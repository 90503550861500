<template>
  <div>
    <div v-if="hasPaymentManageTasks" class="d-flex justify-content-end mb-4 match-statements-button-align">
      <Button
        class="match-statements-button"
        :disabled="!isAdmin || loading || !paymentsToMatch.length || countryCode !== 'IL'"
        @click="showPaymentMatchingModal = true"
      >
        {{ $t('payment.paymentsInProgressTab.matchStatements') }}
      </Button>
    </div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table v-else :columns="columns" :data="tableData" show-index rounded border @row-click="handleRowClick">
        <template #filter-supplierName>
          <el-input
            v-model="supplierSearchFilter"
            :placeholder="$t('commons.searchWithDots')"
            class="text-typography-primary"
          >
            <template #prefix>
              <div class="d-flex align-items-center h-100">
                <SearchIcon width="20px" height="20px" />
              </div>
            </template>
          </el-input>
        </template>
        <template #cell-amount="{ rowData: { amount } }">
          <p>{{ formatMoney(amount) }}</p>
        </template>
        <template #filter-paymentMethod>
          <el-input
            v-model="paymentMethodSearchFilter"
            :placeholder="$t('commons.searchWithDots')"
            class="text-typography-primary"
          >
            <template #prefix>
              <div class="d-flex align-items-center h-100">
                <SearchIcon width="20px" height="20px" />
              </div>
            </template>
          </el-input>
        </template>
        <template #cell-paymentDate="{ rowData: { paymentDate } }">
          <p>{{ formatDate(paymentDate) }}</p>
        </template>
        <template #filter-paymentDate>
          <el-date-picker
            v-model="dateRange"
            format="dd MMMM yyyy"
            type="daterange"
            :start-placeholder="$t('payment.paymentsInProgressTab.filters.startDate')"
            :end-placeholder="$t('payment.paymentsInProgressTab.filters.endDate')"
          >
          </el-date-picker>
        </template>
        <template #cell-status="{ rowData: { status, failed } }">
          <Tag :type="failed ? 'danger' : 'info'">
            {{ status }}
          </Tag>
        </template>
        <template #filter-status>
          <el-input
            v-model="statusSearchFilter"
            :placeholder="$t('commons.searchWithDots')"
            class="text-typography-primary"
          >
            <template #prefix>
              <div class="d-flex align-items-center h-100">
                <SearchIcon width="20px" height="20px" />
              </div>
            </template>
          </el-input>
        </template>
        <template #cell-approvedAt="{ rowData: { approvedAt } }">
          <p dir="ltr">
            {{
              approvedAt
                ? new Date(approvedAt).toLocaleDateString($i18n.locale, {
                    weekday: 'short',
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })
                : '-'
            }}
          </p>
        </template>
      </Table>
    </div>
    <PaymentMatchingModal
      v-if="showPaymentMatchingModal"
      :suppliers="suppliers"
      :payments="paymentsToMatch"
      @close="showPaymentMatchingModal = false"
      @match="$emit('match', $event)"
    />
    <EventMapModal v-if="selectedPayment" :activity="selectedPayment" @close="selectedPayment = null" />
  </div>
</template>

<script>
import { ref } from 'vue';

import { Table, Tag, Button, TableLoadingSkeleton } from '@/modules/core';
import { SearchIcon } from '@/assets/icons';
import { useTenancy, useUser } from '@/modules/auth';
import { useGlobalPermissions } from '@/modules/permissions';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { formatDate, formatDateTime, formatBilledDate } from '../../../tools/formatters';
import PaymentMatchingModal from '../paymentMatching';

const TABLE_HEADER = {
  SUPPLIER_NAME: 'supplierName',
  TOTAL_INVOICES: 'totalInvoices',
  INVOICE_DATE: 'invoiceDate',
  AMOUNT: 'amount',
  PAYMENT_METHOD: 'paymentMethod',
  PAYMENT_DATE: 'paymentDate',
  APPROVED_BY: 'approvedBy',
  APPROVED_AT: 'approvedAt',
  STATUS: 'status',
};

const getSort = ({ direction, column }) => {
  const { SUPPLIER_NAME, TOTAL_INVOICES, AMOUNT, PAYMENT_METHOD, PAYMENT_DATE, APPROVED_BY, APPROVED_AT, STATUS } =
    TABLE_HEADER;
  switch (column) {
    case SUPPLIER_NAME:
      return (a, b) => a[SUPPLIER_NAME].localeCompare(b[SUPPLIER_NAME]) * direction;

    case TOTAL_INVOICES:
      return (a, b) => (a[TOTAL_INVOICES] - b[TOTAL_INVOICES]) * direction;

    case AMOUNT:
      return (a, b) => (a[AMOUNT] - b[AMOUNT]) * direction;

    case PAYMENT_METHOD:
      return (a, b) => a[PAYMENT_METHOD].localeCompare(b[PAYMENT_METHOD]) * direction;

    case PAYMENT_DATE:
      return (a, b) => (a[PAYMENT_DATE] - b[PAYMENT_DATE]) * direction;

    case APPROVED_BY:
      return (a, b) => a[APPROVED_BY].localeCompare(b[APPROVED_BY]) * direction;

    case APPROVED_AT:
      return (a, b) => (a[APPROVED_AT] - b[APPROVED_AT]) * direction;

    case STATUS:
      return (a, b) => a[STATUS].localeCompare(b[STATUS]) * direction;

    default:
      return () => 0;
  }
};

export default {
  components: {
    Table,
    Tag,
    Button,
    SearchIcon,
    PaymentMatchingModal,
    TableLoadingSkeleton,
    EventMapModal: () => import('@/modules/eventMapModal/EventMapModal'),
  },
  props: {
    payments: { type: Array, default: () => [] },
    tasks: { type: Array, default: () => [] },
    suppliers: { type: Array, default: () => [] },
    loading: { type: Boolean, default: false },
  },
  setup() {
    const { isAdmin } = useUser();
    const { formatToCurrency } = useCurrency();
    const { currentTenant } = useTenancy();
    const countryCode = currentTenant.value?.countryCode;

    const { hasPaymentManageTasks } = useGlobalPermissions();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      isAdmin,
      countryCode,
      activeSort: ref({ direction: 1, column: TABLE_HEADER.PAYMENT_DATE }),
      supplierSearchFilter: ref(''),
      statusSearchFilter: ref(''),
      paymentMethodSearchFilter: ref(''),
      dateRange: ref(''),
      formatMoney,
      formatDateTime,
      showPaymentMatchingModal: ref(false),
      selectedPayment: ref(null),
      hasPaymentManageTasks,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('payment.paymentsInProgressTab.headers.supplierName'),
          key: TABLE_HEADER.SUPPLIER_NAME,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.SUPPLIER_NAME, direction }),
          filterActive: !!this.supplierSearchFilter,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.totalInvoices'),
          key: TABLE_HEADER.TOTAL_INVOICES,
          width: '130px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.TOTAL_INVOICES, direction }),
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.amount'),
          key: TABLE_HEADER.AMOUNT,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.AMOUNT, direction }),
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.paymentMethod'),
          key: TABLE_HEADER.PAYMENT_METHOD,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.PAYMENT_METHOD, direction }),
          filterActive: !!this.paymentMethodSearchFilter,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.approvedBy'),
          key: TABLE_HEADER.APPROVED_BY,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.APPROVED_BY, direction }),
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.approvedAt'),
          key: TABLE_HEADER.APPROVED_AT,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.APPROVED_AT, direction }),
          width: '200px',
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.paymentDate'),
          key: TABLE_HEADER.PAYMENT_DATE,
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.PAYMENT_DATE, direction }),
          filterActive: !!this.dateRange,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.status'),
          key: TABLE_HEADER.STATUS,
          width: '150px',
          sortCallback: (direction) => (this.activeSort = { column: TABLE_HEADER.STATUS, direction }),
          filterActive: !!this.statusSearchFilter,
        },
      ];
    },
    tableData() {
      return this.payments
        .map(
          ({ id: paymentId, supplierId, billingLinks, amount, transactions, date, createdBy, createdAt, failed }) => {
            const supplier = this.suppliers.find(({ id }) => id === supplierId);
            return {
              [TABLE_HEADER.SUPPLIER_NAME]: supplier?.name ?? '-',
              [TABLE_HEADER.TOTAL_INVOICES]: billingLinks.length,
              [TABLE_HEADER.AMOUNT]: amount,
              [TABLE_HEADER.PAYMENT_METHOD]: `${this.$t(
                `payment.exports.paymentMethods.shortName.${transactions[0].paymentMethod}`
              )}${transactions[0].directDebit ? ` - ${this.$t('payment.paymentTable.directDebit')}` : ''}`,
              [TABLE_HEADER.PAYMENT_DATE]: new Date(date),
              [TABLE_HEADER.INVOICE_DATE]: '-' ?? formatBilledDate(billingLinks),
              [TABLE_HEADER.APPROVED_BY]: createdBy
                ? `${createdBy.profile.firstName} ${createdBy.profile.lastName}`
                : '-',
              [TABLE_HEADER.APPROVED_AT]: new Date(createdAt),
              [TABLE_HEADER.STATUS]: this.getStatus(paymentId, transactions[0].instructionNumber, failed),
              paymentId,
              failed,
            };
          }
        )
        .filter(
          ({ supplierName, paymentMethod, paymentDate, status }) =>
            supplierName.includes(this.supplierSearchFilter) &&
            paymentMethod.includes(this.paymentMethodSearchFilter) &&
            status.includes(this.statusSearchFilter) &&
            (!this.dateRange || (this.dateRange[0] <= paymentDate && paymentDate <= this.dateRange[1]))
        )
        .sort(getSort(this.activeSort));
    },
    paymentsToMatch() {
      return this.payments.filter((p) => p.transactions[0].instructionNumber && !p.transactions[0].reference);
    },
  },
  methods: {
    formatDate,
    handleRowClick(index) {
      this.selectedPayment = {
        type: 'payment',
        id: this.tableData[index].paymentId,
      };
    },
    getStatus(paymentId, instructionNumber, failed) {
      if (failed) return this.$t('payment.paymentsInProgressTab.failed');
      if (instructionNumber)
        return this.$t(
          `payment.paymentsInProgressTab.${this.countryCode === 'IL' ? 'waitingForSignature' : 'inProcess'}`
        );
      const relevantTask = this.tasks.find((task) => task.data.paymentId === paymentId);
      return relevantTask
        ? this.$t('payment.paymentsInProgressTab.waitingForUpload')
        : this.$t('payment.paymentsInProgressTab.waitingForExecutionDate');
    },
  },
};
</script>

<style lang="scss" scoped>
.match-statements-button-align {
  margin-top: -42px;
}
.match-statements-button {
  z-index: 1030;
}
</style>
