<template>
  <div>
    <h3 class="mb-4">
      {{ $t('payment.paymentsInProgressTab.title') }}
    </h3>
    <PaymentStatusTable
      v-if="activeTab === 0"
      :suppliers="suppliers"
      :payments="paymentsInProgress"
      :tasks="tasks"
      :loading="paymentsLoading"
      @match="handleMatch"
    />
    <FilesToUploadTable
      v-if="activeTab === 1"
      :payments="paymentsGroupedByDate"
      :suppliers="suppliers"
      :loading="loading"
      @submit="handleFileSubmit"
    />
  </div>
</template>

<script>
import { computed, watch, provide, ref, getCurrentInstance } from 'vue';
import { groupBy, prop, omit } from 'ramda';

import PaymentStatusTable from './PaymentStatusTable';
import FilesToUploadTable from './FilesToUploadTable';
import {
  PAYMENT_PAGE_CONTEXT,
  usePaymentComplete,
  usePaymentsInProgress,
  useCreatePayment,
} from '../../../compositions';

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export default {
  components: {
    PaymentStatusTable,
    FilesToUploadTable,
  },
  props: {
    activeTab: { type: Number, default: 0 },
    suppliers: { type: Array, default: () => [] },
    supplierId: { type: String, default: null },
    tasksCount: { type: Number, required: true },
  },
  setup(props, { emit }) {
    const root = getCurrentInstance().proxy;
    const tenantId = ref(root.$route.params.tenantId);
    const {
      loading: paymentsLoading,
      payments: allPaymentsInProgress,
      refetch: refetchPaymentsInProgress,
      paymentTasks: tasks,
      paymentTaskLoading: loading,
      refetchPaymentTasks,
      patchTransaction,
      patchTransactionLoading,
    } = usePaymentsInProgress(computed(() => ({ businessId: tenantId.value, completed: false })));

    const { onDone } = useCreatePayment();
    let refetchTimer = null;

    onDone(() => {
      if (refetchTimer) clearTimeout(refetchTimer);
      refetchTimer = setTimeout(() => {
        refetchPaymentTasks();
        refetchPaymentsInProgress();
      }, 10000);
    });

    const getPaymentByTransactionId = (transactionId) =>
      allPaymentsInProgress.value.find(({ transactions }) => transactions.some(({ id }) => id === transactionId));

    provide(PAYMENT_PAGE_CONTEXT, { patchTransaction, patchTransactionLoading, getPaymentByTransactionId });

    const transformedAllPaymentsInProgress = computed(() =>
      allPaymentsInProgress.value.map((p) => ({
        ...omit(['requestedDate'], p),
        date: p.date || p.requestedDate,
        amount: p.amount * (p.isRefund ? -1 : 1),
      }))
    );
    const paymentsInProgress = computed(() =>
      transformedAllPaymentsInProgress.value.filter((p) => !props.supplierId || props.supplierId === p.supplierId)
    );
    const paymentIds = computed(() => tasks.value.map(({ data }) => data.paymentId));
    const paymentsToUpload = computed(() => paymentsInProgress.value.filter(({ id }) => paymentIds.value.includes(id)));
    const paymentsGroupedByDate = computed(() => groupBy(prop('date'))(paymentsToUpload.value));
    watch(
      paymentsGroupedByDate,
      (paymentsGroupedByDate) => emit('update:tasksCount', Object.keys(paymentsGroupedByDate).length),
      { immediate: true }
    );

    const { completePayment } = usePaymentComplete();

    return {
      paymentsInProgress,
      loading,
      paymentsLoading,
      tasks,
      refetchPaymentTasks,
      refetchPaymentsInProgress,
      paymentsGroupedByDate,
      completePayment,
    };
  },
  computed: {
    paymentsById() {
      return this.paymentsInProgress.reduce((idsMap, payment) => ({ ...idsMap, [payment.id]: payment }), {});
    },
  },
  methods: {
    async handleMatch({ onDone, onError, data }) {
      try {
        const promises = Object.entries(data).map(([paymentId, transactionData]) => {
          const payment = this.paymentsById[paymentId];
          const transactionId = payment.transactions[0].id;
          const { reference, date } = transactionData;
          return this.completePayment({
            paymentId,
            transactionId,
            transactionParams: {
              reference,
              date,
            },
          });
        });

        await Promise.all(promises);
        this.refetchPaymentsInProgress();
        this.$emit('refetch-payments');
        onDone();
      } catch (error) {
        console.error(error);
        onError();
      }
    },
    async handleFileSubmit() {
      await sleep(30000);
      this.refetchPaymentsInProgress();
      this.refetchPaymentTasks();
      this.$emit('refetch-payments');
    },
  },
};
</script>
