<template>
  <div>
    <div>
      <TableLoadingSkeleton v-if="loading" />
      <Table
        v-else
        :columns="columns"
        :data="tableData"
        show-index
        rounded
        border
        :hover="isAdmin"
        @row-click="handleRowClick"
      >
        <template #cell-paymentDueDate="{ rowData: { paymentDueDate } }">
          <p>{{ formatDate(paymentDueDate) }}</p>
        </template>
        <template #cell-amount="{ rowData: { amount } }">
          <p>{{ formatMoney(amount) }}</p>
        </template>
        <template #cell-status>
          <Tag type="info">
            {{ $t('payment.paymentsInProgressTab.waitingForUpload') }}
          </Tag>
        </template>
      </Table>
    </div>
    <BankPaymentDocumentation
      v-if="selectedPaymentData"
      :payments="selectedPaymentData"
      :suppliers="suppliers"
      @close="selectedPaymentData = null"
      @submit="handleBankPaymentSubmitted"
    />
  </div>
</template>

<script>
import { uniq } from 'ramda';

import { options } from '@/locale/dateConfig';
import { Table, Tag, TableLoadingSkeleton } from '@/modules/core';
import { useUser } from '@/modules/auth';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import BankPaymentDocumentation from './components/BankPaymentDocumentation';

const TABLE_HEADER = {
  PAYMENT_COUNT: 'paymentCount',
  PAYMENT_DUE_DATE: 'paymentDueDate',
  SUPPLIER_COUNT: 'supplierCount',
  AMOUNT: 'amount',
  BILLING_ACCOUNT: 'billingAccount',
  STATUS: 'status',
};

export default {
  components: { Table, Tag, TableLoadingSkeleton, BankPaymentDocumentation },
  props: {
    payments: { type: Object, default: () => {} },
    loading: { type: Boolean, default: false },
    suppliers: { type: Array, default: () => [] },
  },
  setup() {
    const { isAdmin } = useUser();

    const { formatToCurrency } = useCurrency();

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return { isAdmin, formatMoney };
  },
  data() {
    return {
      selectedPaymentData: null,
    };
  },
  computed: {
    columns() {
      return [
        {
          header: this.$t('payment.paymentsInProgressTab.headers.paymentCount'),
          key: TABLE_HEADER.PAYMENT_COUNT,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.paymentDueDate'),
          key: TABLE_HEADER.PAYMENT_DUE_DATE,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.supplierCount'),
          key: TABLE_HEADER.SUPPLIER_COUNT,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.amount'),
          key: TABLE_HEADER.AMOUNT,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.billingAccount'),
          key: TABLE_HEADER.BILLING_ACCOUNT,
        },
        {
          header: this.$t('payment.paymentsInProgressTab.headers.status'),
          key: TABLE_HEADER.STATUS,
        },
      ];
    },
    tableData() {
      return Object.entries(this.payments)
        .map(([date, data]) => {
          return {
            [TABLE_HEADER.PAYMENT_COUNT]: data.length,
            [TABLE_HEADER.PAYMENT_DUE_DATE]: date,
            [TABLE_HEADER.SUPPLIER_COUNT]: uniq(data.map(({ supplierId }) => supplierId)).length,
            [TABLE_HEADER.AMOUNT]: data.reduce((acc, data) => acc + data.amount, 0),
            [TABLE_HEADER.BILLING_ACCOUNT]: '-',
            paymentData: data,
          };
        })
        .sort((a, b) => new Date(a[TABLE_HEADER.PAYMENT_DUE_DATE]) - new Date(b[TABLE_HEADER.PAYMENT_DUE_DATE]));
    },
  },
  methods: {
    handleRowClick(rowIndex) {
      if (this.isAdmin) this.selectedPaymentData = this.tableData[rowIndex].paymentData;
    },
    formatDate(ms) {
      return ms ? new Date(ms).toLocaleDateString(this.$i18n.locale, options.short) : '-';
    },
    handleBankPaymentSubmitted() {
      this.selectedPaymentData = null;
      this.$emit('submit');
    },
  },
};
</script>
